import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import FavoriteContent from '../components/FavoriteContent'
import FavoriteLinks from '../components/FavoriteLinks'

const favoriteSingle = ({ data, pageContext }) => {
  const {
    type,
    id,
    favoriteTitle,
    slug,
    favoritePhotos,
    favoriteLink,
  } = data.contentfulFavorite
  const previous = pageContext.prev
  const next = pageContext.next

  function capitalize(str) {
    if (str.includes('-')) {
      const pos = str.indexOf('-')
      return (
        str.charAt(0).toUpperCase() +
        str.slice(1, pos - 1) +
        str.replace('-', ' ') +
        str.charAt(pos + 1).toUpperCase() +
        str.slice(pos + 2)
      )
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }

  const typeTitle = capitalize(type)

  return (
    <Layout>
      <Helmet>
        <title>{`${
          config.siteTitle
        } | Favorite ${typeTitle} | ${favoriteTitle}`}</title>
      </Helmet>
      <Container>
        <FavoriteContent
          type={type}
          id={id}
          slug={slug}
          favoritePhotos={favoritePhotos}
          favoriteLink={favoriteLink}
        />
      </Container>
      <FavoriteLinks previous={previous} next={next} type={type} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulFavorite(slug: { eq: $slug }) {
      type
      id
      favoriteTitle
      slug
      favoritePhotos {
        title
        fluid(maxWidth: 800, cropFocus: FACES, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      favoriteLink {
        itemName
        imageUrl
        itemUrl
      }
    }
  }
`

export default favoriteSingle
