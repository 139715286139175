import React, { Component } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Logo from '../components/Logo'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

const FavoriteContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 5px;
  overflow: hidden;
  margin: 50px 0;
`

const ImageSection = styled.div``

const ImageCarousel = styled.div`
  background: ${props => props.theme.colors.base};
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  justify-items: center;
  padding: 15px 0;
  button:disabled {
    cursor: unset;
  }
  .carousel__back-button,
  .carousel__next-button {
    background: ${props => props.theme.colors.secondary};
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  .carousel__back-button:disabled,
  .carousel__next-button:disabled {
    background: gray;
  }
  .carousel__dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    background-color: ${props => props.theme.colors.secondary};
    margin: auto 3px;
    padding: 0;
  }
  .carousel__dot:disabled {
    background: ${props => props.theme.colors.tertiary};
  }
`

const LinkSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  .containerLink {
    padding: 100px;
  }
  h4 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 5px;
  }
  background: ${props => props.theme.colors.base};
`

class FavoriteContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageNum: 0,
      images: this.props.favoritePhotos.length,
    }
  }
  handleImgChange = () => {
    this.setState(prevState => ({
      imageNum: prevState.imageNum + 1,
    }))
  }
  render() {
    const favorite = this.props
    return (
      <div>
        <Logo />
        <FavoriteContainer>
          <ImageSection>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={favorite.favoritePhotos.length}
            >
              <Slider>
                {favorite.favoritePhotos.map((favImg, index) => (
                  <Slide index={index} key={index}>
                    <Img fluid={favImg.fluid} alt={favImg.title} />
                  </Slide>
                ))}
              </Slider>
              {favorite.favoritePhotos.length > 1 && (
                <ImageCarousel>
                  <ButtonBack>Back</ButtonBack>
                  <DotGroup />
                  <ButtonNext>Next</ButtonNext>
                </ImageCarousel>
              )}
            </CarouselProvider>
          </ImageSection>
          <LinkSection>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={favorite.favoriteLink.length}
            >
              <Slider>
                {favorite.favoriteLink.map((favLink, index) => (
                  <Slide index={index} key={index}>
                    <div className="containerLink">
                      <h4>{favLink.itemName}</h4>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={favorite.favoriteLink[index].itemUrl}
                      >
                        <img
                          src={favorite.favoriteLink[index].imageUrl}
                          alt={favLink.itemName}
                        />
                      </a>
                    </div>
                  </Slide>
                ))}
              </Slider>
              {favorite.favoriteLink.length > 1 && (
                <ImageCarousel>
                  <ButtonBack>Back</ButtonBack>
                  <DotGroup />
                  <ButtonNext>Next</ButtonNext>
                </ImageCarousel>
              )}
            </CarouselProvider>
          </LinkSection>
        </FavoriteContainer>
      </div>
    )
  }
}

export default FavoriteContent
